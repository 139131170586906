import React from "react";

import ChatHeader from "../../Components/ChatHeader";
import ChatBody from "../../Components/ChatBody";
import ChatFooter from "../../Components/ChatFooter";
import { Box, Stack } from "@mui/material";

const Chat = () => {
  return (
    <Stack height={"100%"} border={1} borderColor={"divider"}>
      <ChatHeader />

      <Box flexGrow={1} p={1} overflow={"auto"}>
        <ChatBody />
      </Box>

      <ChatFooter />
    </Stack>
  );
};

export default Chat;
