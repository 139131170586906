import { createBrowserRouter } from "react-router-dom";
import Home from "./Home/Home";
import Chat from "./Chat/Chat";
import { OAUTH_URL, API_URL, SOCKET_URL } from "../helpers/variables";
import { io } from "socket.io-client";

async function authUser(auth_id) {
  let res = await fetch(`${OAUTH_URL}/login?auth_id=${auth_id}`);
  let status = res.status;

  if (status === 200) {
    let data = await res.json();
    return data;
  } else if (status === 404) {
    throw new Error(`Subscription not found (status: ${status})`);
  } else if (status === 403) {
    throw new Error(`Bad request: authentication failed (status: ${status})`);
  } else {
    throw new Error(`Unexpected error in authentication (status: ${status})`);
  }
}

async function checkRoom(roomID) {
  let res = await fetch(`${API_URL}/check_room?room_id=${roomID}`);
  let status = res.status;
  if (status === 200) {
    let data = await res.text();
    return JSON.parse(data);
  } else if (status === 404) {
    throw new Error(`Room not found (status: ${status})`);
  } else {
    throw new Error(`Unexpected error (status: ${status})`);
  }
}

async function checkUser(room, userID) {
  let participants = room["participants"] || [];
  let user = participants.find((i) => i["userID"] === userID);
  if (!user) {
    throw new Error(`User Not found`);
  }
  return user;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/chat",
    loader: async ({ request }) => {
      const url = new URL(request.url);

      const authID = url.searchParams.get("authID");
      const roomID = url.searchParams.get("roomID");
      const userID = url.searchParams.get("userID");

      if (!authID || !roomID || !userID) {
        throw new Error("Missing required parameters.");
      }

      let auth = await authUser(authID);
      let room = await checkRoom(roomID);
      let user = await checkUser(room, userID);

      const socket = io(SOCKET_URL, {
        path: "/socket.io/",
      });
      socket.emit("joinRoom", roomID);

      return { socket, room, user };
    },
    element: <Chat />,
  },
]);

export default router;
