// import React, { useEffect } from "react";
// import { io } from "socket.io-client";

// const SOCKET_SERVER_URL = "http://localhost:8000";

// function App() {
//   useEffect(() => {
//     const socket = io(SOCKET_SERVER_URL);

//     socket.on("connect", () => {
//       console.log("Connected to Socket.IO server");
//     });

//     return () => {
//       socket.disconnect();
//     };
//   }, []);

//   return (
//     <div className="App">
//       <h1>Socket.IO with React</h1>
//     </div>
//   );
// }

// export default App;

import React from "react";
import router from "./Routes/routes";
import { RouterProvider } from "react-router-dom";

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
