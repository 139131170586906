import React from "react";
import { Chip, Typography, Box, ChipProps, styled } from "@mui/material";
import { format } from "date-fns";

const CustomChip = styled(Chip)(({ theme }) => ({
  height: "auto",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  maxWidth: "70%",
  "& .MuiChip-label": {
    display: "block",
    whiteSpace: "normal",
  },
}));

interface Message {
  alignment: "left" | "right";
  senderName: string;
  content: string;
  timestamp: string;
}

type ChatBubbleProps = {
  message: Message;
} & ChipProps;

const ChatBubble: React.FC<ChatBubbleProps> = ({ message, ...props }) => {
  return (
    <CustomChip
      label={
        <>
          <Typography variant="caption">{message.senderName}</Typography>
          <Typography variant="body1">{message.content}</Typography>
          <Box width={"100%"} textAlign={"right"}>
            <Typography variant="caption">
              {format(new Date(message.timestamp), "yyyy-MM-dd HH:mm")}
            </Typography>
          </Box>
        </>
      }
      {...props}
    />
  );
};

export default ChatBubble;
