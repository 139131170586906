import { FilePresent, Send } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";

const ChatFooter = () => {
  const { user, socket, room } = useLoaderData();
  const userID = user["userID"];
  const roomID = room["_id"];
  const username = user["username"];

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const inputRef = useRef(null);

  useEffect(() => {
    if (text === "") {
      inputRef.current?.focus();
    }
  }, [text]);

  const sendMessage = async (text, type, s3_key) => {
    setLoading(true);
    const trimmedText = text.trim();
    if (trimmedText === "") {
      alert("Please enter text");
      setLoading(false);
      return;
    }
    let message = {
      senderID: userID,
      senderName: username,
      content: trimmedText,
      type,
      readBy: [],
    };
    if (s3_key) {
      message["attachmentKey"] = s3_key;
    }
    await socket.emit("sendTextMessage", { roomID, message });
    setText("");
    setLoading(false);
  };

  const handleFileSelect = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = (e) => {
      const files = Array.from(e.target.files);

      // await uPload to s3
      let s3_key = "";
      sendMessage("", "attachment", s3_key);
    };
    input.click();
  };

  return (
    <Box
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
        sendMessage(e.target.message.value, "text");
      }}
      direction={"row"}
      gap={1}
      p={1}
    >
      <TextField
        inputRef={inputRef}
        fullWidth
        name="message"
        value={text}
        placeholder="Type message"
        onChange={(e) => setText(e.target.value)}
        disabled={loading}
        slotProps={{
          input: {
            startAdornment: (
              <IconButton>
                <FilePresent />
              </IconButton>
            ),
            endAdornment: (
              <Button
                disableElevation
                disabled={loading}
                variant="contained"
                type="submit"
              >
                <Send />
              </Button>
            ),
          },
        }}
      />
    </Box>
  );
};

export default ChatFooter;
