import { ArrowForward } from "@mui/icons-material";
import { Box, Fab, Stack, TextField } from "@mui/material";
import React from "react";

export default function Home() {
  return (
    <Stack height={"100vh"} justifyContent={"center"} alignItems={"center"}>
      <Stack
        gap={3}
        width={"25%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        component={"form"}
        method="get"
        action="/chat"
        target="_blank"
      >
        <TextField
          required
          size="small"
          fullWidth
          name="authID"
          label="Auth ID"
        />
        <TextField
          required
          size="small"
          fullWidth
          name="userID"
          label="User ID"
        />
        <TextField
          required
          size="small"
          fullWidth
          name="roomID"
          label="Room ID"
        />
        <Box>
          <Fab type="submit" color="primary" size="small">
            <ArrowForward />
          </Fab>
        </Box>
      </Stack>
    </Stack>
  );
}
