import React from "react";

import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Call, VideoCall } from "@mui/icons-material";

const ChatHeader = () => {
  return (
    <AppBar position="static" sx={{ boxShadow: "none" }}>
      <Toolbar variant="dense" sx={{ gap: 1 }}>
        <Typography flexGrow={1}>Chat</Typography>

        <IconButton color="inherit">
          <Call />
        </IconButton>

        <IconButton color="inherit">
          <VideoCall />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default ChatHeader;
