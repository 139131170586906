import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useLoaderData } from "react-router-dom";
import { API_URL } from "../helpers/variables";
import { Socket } from "socket.io-client";
import ChatBubble from "./ChatBubble.tsx";
import { AccountCircle, ArrowBack, ArrowDownward } from "@mui/icons-material";
import { Virtuoso } from "react-virtuoso";

const ChatBody = () => {
  const loaderData = useLoaderData();

  const { user, room } = loaderData;
  const userID = user["userID"];
  const roomID = room["_id"];

  /**
   * @type {Socket}
   */
  const socket = loaderData["socket"];

  const [messagesList, setMessagesList] = useState([]);
  const lastMessage = messagesList?.length
    ? messagesList[messagesList?.length - 1]
    : null;

  const virtuoso = useRef(null);

  const [newMessageToggle, setNewMessageToggle] = useState(false);

  useEffect(() => {
    const init = async () => {
      getInitialMessages();
      onFocus();
      socket.on("receiveTextMessage", (message) => {
        setMessagesList((prevMessages) => [...prevMessages, message]);
      });
      window.addEventListener("focus", onFocus);
    };
    init();
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, []);

  useEffect(() => {
    if (document.hasFocus()) {
      onFocus();
    }
  }, [messagesList]);

  const onFocus = () => {
    socket.emit("updateMessages", { roomID, user });
  };

  async function getInitialMessages() {
    let res = await fetch(`${API_URL}/get_messages?room_id=${roomID}`);
    let data = await res.json();
    setMessagesList(data);
  }

  const onFollowOutputHandler = useCallback(
    (atBottom) => {
      if (atBottom || lastMessage?.senderID === userID) {
        return "auto";
      } else {
        setNewMessageToggle(true);
        return false;
      }
    },
    [lastMessage]
  );

  const itemContent = useCallback(
    (index, message) => (
      <Stack p={1}>
        {message["senderID"] === userID ? (
          <ChatBubble
            key={index}
            message={message}
            color={"primary"}
            sx={{ alignSelf: "flex-end" }}
          />
        ) : (
          <ChatBubble
            key={index}
            message={message}
            sx={{ alignSelf: "flex-start" }}
          />
        )}
      </Stack>
    ),
    []
  );

  return (
    <>
      <Stack gap={2} height={"100%"} position={"relative"}>
        <Virtuoso
          data={messagesList}
          followOutput={onFollowOutputHandler}
          initialTopMostItemIndex={messagesList.length}
          itemContent={itemContent}
          ref={virtuoso}
          onScroll={(e) => {
            const { scrollHeight, scrollTop, clientHeight } = e.target;
            const threshold = 1;
            if (
              Math.abs(scrollHeight - scrollTop - clientHeight) <= threshold
            ) {
              setNewMessageToggle(false);
            }
          }}
        />
        {newMessageToggle && (
          <Box
            position={"absolute"}
            zIndex={100}
            bgcolor={"background.paper"}
            bottom={16}
            right={32}
          >
            <Button
              onClick={() => {
                virtuoso.current.scrollToIndex({
                  index: messagesList.findIndex((i) => i === lastMessage) + 1,
                  align: "end",
                  behavior: "auto",
                });
              }}
              startIcon={<ArrowDownward />}
              variant="outlined"
            >
              New Message
            </Button>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default ChatBody;
